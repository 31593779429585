// prettier-ignore
export const PlayIcon = () => {
    return (
        <svg width="11px" height="14px" viewBox="0 0 11 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Rounded" transform="translate(-753.000000, -955.000000)">
                    <g id="AV" transform="translate(100.000000, 852.000000)">
                        <g id="-Round-/-AV-/-play_arrow" transform="translate(646.000000, 98.000000)">
                            <g>
                                <rect id="Rectangle-Copy-50" x="0" y="0" width="24" height="24"></rect>
                                <path d="M7,6.82 L7,17.18 C7,17.97 7.87,18.45 8.54,18.02 L16.68,12.84 C17.3,12.45 17.3,11.55 16.68,11.15 L8.54,5.98 C7.87,5.55 7,6.03 7,6.82 Z" id="🔹Icon-Color" fill="#1D1D1D"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

// prettier-ignore
export const PauseIcon = () => {
    return (
        <svg width="11px" height="14px" viewBox="0 0 6 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-227.000000, -3765.000000)" fill="#000000">
                    <g id="icons" transform="translate(56.000000, 160.000000)">
                        <path d="M172,3605 C171.448,3605 171,3605.448 171,3606 L171,3612 C171,3612.552 171.448,3613 172,3613 C172.552,3613 173,3612.552 173,3612 L173,3606 C173,3605.448 172.552,3605 172,3605 M177,3606 L177,3612 C177,3612.552 176.552,3613 176,3613 C175.448,3613 175,3612.552 175,3612 L175,3606 C175,3605.448 175.448,3605 176,3605 C176.552,3605 177,3605.448 177,3606" id="pause-[#1006]"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

// prettier-ignore
export const MenuIcon = (props) => {
    return (
        <svg width="18px" height="12px" viewBox="0 0 18 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Rounded" transform="translate(-885.000000, -3438.000000)">
                    <g id="Navigation" transform="translate(100.000000, 3378.000000)">
                        <g id="-Round-/-Navigation-/-menu" transform="translate(782.000000, 54.000000)">
                            <g transform="translate(0.000000, 0.000000)">
                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M4,18 L20,18 C20.55,18 21,17.55 21,17 C21,16.45 20.55,16 20,16 L4,16 C3.45,16 3,16.45 3,17 C3,17.55 3.45,18 4,18 Z M4,13 L20,13 C20.55,13 21,12.55 21,12 C21,11.45 20.55,11 20,11 L4,11 C3.45,11 3,11.45 3,12 C3,12.55 3.45,13 4,13 Z M3,7 C3,7.55 3.45,8 4,8 L20,8 C20.55,8 21,7.55 21,7 C21,6.45 20.55,6 20,6 L4,6 C3.45,6 3,6.45 3,7 Z" id="🔹-Icon-Color" fill={props.color}></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

// prettier-ignore
export const InfoIcon = () => {
    return (
        <svg style={styles.icon} id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px">
            <g>
                <path d="M256,32C132.3,32,32,132.3,32,256s100.3,224,224,224c123.7,0,224-100.3,224-224S379.7,32,256,32z M276.2,358.7   c-0.5,17.8-13.7,28.8-30.8,28.3c-16.4-0.5-29.3-12.2-28.8-30.1c0.5-17.8,14.1-29.1,30.5-28.6C264.3,328.8,276.8,340.9,276.2,358.7z    M324.9,231.4c-4.2,5.9-13.6,13.5-25.4,22.7l-13.1,9c-6.4,4.9-10.4,10.7-12.5,17.3c-1.1,3.5-1.9,12.6-2.1,18.7   c-0.1,1.2-0.8,3.9-4.5,3.9c-3.7,0-35,0-39.1,0c-4.1,0-4.6-2.4-4.5-3.6c0.6-16.6,3-30.3,9.9-41.3c9.3-14.8,35.5-30.4,35.5-30.4   c4-3,7.1-6.2,9.5-9.7c4.4-6,8-12.7,8-19.9c0-8.3-2-16.2-7.3-22.8c-6.2-7.7-12.9-11.4-25.8-11.4c-12.7,0-20.1,6.4-25.4,14.8   c-5.3,8.4-4.4,18.3-4.4,27.3H175c0-34,8.9-55.7,27.7-68.5c12.7-8.7,28.9-12.5,47.8-12.5c24.8,0,44.5,4.6,61.9,17.8   c16.1,12.2,24.6,29.4,24.6,52.6C337,209.7,332,221.7,324.9,231.4z" />
            </g>
        </svg>
    );
};

// prettier-ignore
export const TimeIcon = () => {
    return (
        <svg width="1em" viewBox="0 0 32 32" id="Lager_44" data-name="Lager 44">
            <g id="Group_15" data-name="Group 15">
                <path id="Path_51" data-name="Path 51" d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm0,28A12,12,0,1,1,28,16,12.013,12.013,0,0,1,16,28Z" fill="#040505" />
                <path id="Path_52" data-name="Path 52" d="M20.02,16H17a1,1,0,0,1-1-1V11.98A1.979,1.979,0,0,0,14.02,10h-.04A1.979,1.979,0,0,0,12,11.98v6.04A1.979,1.979,0,0,0,13.98,20h6.04A1.979,1.979,0,0,0,22,18.02v-.04A1.979,1.979,0,0,0,20.02,16Z" fill="#040505" />
            </g>
        </svg>
    )
}

// prettier-ignore
export const HomeIcon = () => {
    return (
        <svg id="Capa_1" x="0px" y="0px" width="1em" viewBox="0 0 552.912 552.912">
            <g>
                <g>
                    <path d="M276.462,3.693L276.462,3.693L276.462,3.693L276.462,3.693L0,242.311l27.551,31.92l35.408-30.58v305.567H221.08V385.688
			h55.382l0,0h55.382v163.531h158.119V243.657l35.403,30.583l27.546-31.923L276.462,3.693z M276.462,261.906
			c-33.82,0-61.237-27.417-61.237-61.234c0-33.814,27.422-61.232,61.237-61.232c33.816,0,61.227,27.418,61.227,61.232
			C337.688,234.483,310.278,261.906,276.462,261.906z"/>
                </g>
            </g>
        </svg>
    )
}

export const BackIcon = () => {
  return (
    <svg version="1.1" width="15px" height="15px" x="0px" y="0px" viewBox="0 0 574.705 574.705">
      <g>
        <g>
          <polygon points="32.354,287.353 319.706,574.705 319.706,383.137 542.352,383.137 542.352,191.568 319.706,191.568 319.706,0" />
        </g>
      </g>
    </svg>
  );
};

export const MedalIcon = () => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 490 490">
      <g>
        <g>
          <g>
            <path
              d="M348.8,230.4v-80c0-11.2-14-10.1-14-10.1L407,0H296.3l-40,78.5l31.9,62.2h-22.5L193.7,0H83l71.9,140.7
				c0,0-14-2.4-14,10.1v80c-28.8,27.2-46.6,66.1-46.6,108.8c0,83.2,67.2,150.4,150.4,150.4S395,422.8,395,339.6
				C395.4,296.5,377.5,258,348.8,230.4z M161.1,160.5H329v54c-24.1-16.3-52.8-25.6-83.9-25.6s-59.8,9.3-83.9,25.6v-54H161.1z
				 M245,445.3c-58.7,0-106.1-47.4-106.1-106.1S186.3,233.1,245,233.1s106.1,47.4,106.1,106.1S303.7,445.3,245,445.3z"
            />
            <circle cx="245" cy="337.7" r="82.8" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const TrophyIcon = () => {
  return (
    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 363.433 363.433">
      <path
        id="XMLID_490_"
        d="M314.716,31.716h-28.984C283.685,13.891,268.512,0,250.147,0H113.286C94.921,0,79.748,13.891,77.701,31.716
	H48.716c-14.912,0-27,12.088-27,27v56c0,14.912,12.088,27,27,27h28.741v16.171c0,12.759,6.864,24.648,17.914,31.028l52.195,30.135
	v24.745h-4.436c-14.058,0-25.495,11.437-25.495,25.495v41.743H91.618c-14.447,0-26.2,11.753-26.2,26.199
	c0,14.447,11.753,26.2,26.2,26.2h180.197c14.447,0,26.2-11.753,26.2-26.2c0-14.446-11.753-26.199-26.2-26.199h-26.019v-41.743
	c0-14.058-11.437-25.495-25.495-25.495h-4.436v-24.745l52.195-30.135c11.05-6.379,17.914-18.269,17.914-31.028v-16.171h28.741
	c14.912,0,27-12.088,27-27v-56C341.716,43.805,329.628,31.716,314.716,31.716z M51.716,111.716v-50h25.741v50H51.716z
	 M311.716,111.716h-25.741v-50h25.741V111.716z"
      />
    </svg>
  );
};

export const RefreshIcon = () => {
  return (
    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 330.006 330.006">
      <g>
        <path
          d="M304.302,209.419c-7.594-3.318-16.435,0.148-19.751,7.739c-20.766,47.527-67.69,78.236-119.546,78.236
		c-41.106,0-79.108-19.32-103.445-51.003l51.23,10.19c8.126,1.617,16.021-3.661,17.638-11.786
		c1.616-8.125-3.661-16.022-11.786-17.638l-84.004-16.709c-8.125-1.612-16.021,3.661-17.638,11.786L0.291,304.238
		c-1.616,8.125,3.661,16.022,11.786,17.638c0.988,0.196,1.972,0.291,2.942,0.291c7.01,0,13.276-4.94,14.696-12.077l9.148-45.992
		c29.972,38.123,76.202,61.296,126.142,61.296c63.78,0,121.496-37.77,147.036-96.225
		C315.358,221.579,311.893,212.736,304.302,209.419z"
        />
        <path
          d="M317.929,8.12c-8.125-1.614-16.022,3.661-17.638,11.786l-9.149,45.997C261.169,27.783,214.94,4.611,165.005,4.611
		c-63.779,0-121.495,37.771-147.038,96.224c-3.317,7.591,0.148,16.434,7.739,19.751c1.956,0.854,3.993,1.259,5.999,1.259
		c5.781,0,11.29-3.362,13.753-8.998c20.768-47.527,67.693-78.237,119.548-78.237c41.099,0,79.1,19.316,103.437,50.995
		l-51.228-10.191c-8.127-1.615-16.022,3.66-17.639,11.785c-1.616,8.125,3.66,16.022,11.785,17.639l84.006,16.711
		c0.971,0.193,1.951,0.288,2.927,0.288c2.945,0,5.849-0.868,8.333-2.528c3.308-2.21,5.602-5.644,6.378-9.546l16.709-84.006
		C331.331,17.633,326.054,9.736,317.929,8.12z"
        />
      </g>
    </svg>
  );
};

const styles = {
  icon: {
    width: "2em",
  },
};
